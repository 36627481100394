import axios from "axios";
import * as types from "./mutation-types";

export const registerEntrada = async ({ commit }, data) => {
  const coordenadas = JSON.stringify(data.coordenadas);
  const result = await axios.post("entradas", {
    user_id: data.entrada.userId,
    nombres: data.entrada.name,
    observaciones: data.entrada.obs,
    time: data.coordenadas.timestamp,
    coordenadas: coordenadas,
    image: data.entrada.imagen,
  });
  if (result.status === 201) {
    await commit(types.ADD_ENTRADA, result.data);
  }
  console.log(result);
  return result;
};

export const registerSalida = async ({ commit }, data) => {
  const coordenadas = JSON.stringify(data.coordenadas);
  const result = await axios.post("salidas", {
    user_id: data.salida.userId,
    nombres: data.salida.name,
    observaciones: data.salida.obs,
    time: data.coordenadas.timestamp,
    coordenadas: coordenadas,
    image: data.salida.imagen,
  });
  if (result.status === 201) {
    await commit(types.ADD_SALIDA, result.data);
  }
  return result;
};

export const registerNovedad = async ({commit},data) =>{ 
  const result = await axios.post('/novedades', data);
  if(result.status === 201){
    await commit(types.ADD_NOVEDAD, result.data);
  }
  return result;
}
