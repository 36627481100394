<template>
  <ion-grid>
    <ion-row class="ion-align-items-center ion-margin-top">
      <ion-col class="ion-padding-top ion-align-items-stretch">
        <ion-row>
          <ion-col>
            <img src="/assets/icon/icon.png" alt="" />
          </ion-col>
        </ion-row>
        <ion-row class="ion-align-items-center">
          <ion-col>
            <form class="ion-padding" @submit.prevent="submitForm">
              <ion-list>
                <ion-item>
                  <ion-label position="floating">Usuario</ion-label>
                  <ion-input type="email" required v-model="loginData.email" />
                </ion-item>
                <ion-item>
                  <ion-label position="floating">Contraseña</ion-label>
                  <ion-input
                    type="password"
                    required
                    v-model="loginData.password"
                  />
                </ion-item>
              </ion-list>
              <ion-button type="submit" expand="block" fill="outline"
                >Ingresar</ion-button
              >
            </form>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>
<script>
import { toastController, loadingController } from "@ionic/vue";
import { IonList, IonItem, IonLabel, IonInput, IonButton } from "@ionic/vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "login-form",
  emits: ["login"],
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
  },
  data() {
    return {
      loginData: {
        email: "",
        password: "",
      },
      toatsMessage: "",
      toatsColor: "",
      timeout: 1000,
    };
  },
  computed: {
    ...mapGetters("auth", ["messageOn"]),
    ...mapGetters("user", ["user"]),
  },
  methods: {
    ...mapActions("auth", ["retrieveToken"]),
    ...mapActions("user", ["retrieveUser"]),
    async submitForm() {
      this.timeout = 100000;
      const loading = await loadingController.create({
        message: "Ingresando al sistema...",
        duration: this.timeout,
      });
      await loading.present();
      const result = await this.retrieveToken(this.loginData);
      if (result === 200) {
        loading.dismiss();
        await this.$router.replace("/home/Inicio");
        await this.retrieveUser();
        const name = this.user.name;
        this.toatsColor = "light";
        this.toatsMessage = `Hola ${name} has ingresado al sistema exitosamente`;
        await this.openToast();
      } else if (result === 400) {
        this.toatsColor = "danger";
        this.toatsMessage = "Ingresa un usuario o contraseña correctos";
        await this.openToast();
      } else if (result === 500) {
        console.log("error 500");
        this.toatsColor = "danger";
        this.toatsMessage =
          "Error de el servidor, contacta con el administrador";
        this.openToast();
      } else {
        this.toatsColor = "danger";
        this.toatsMessage = "Error desconocido, contacte con el administrador";
        await this.openToast();
      }
      loading.dismiss();
      this.loginData.password = "";
    },
    async openToast() {
      const toast = await toastController.create({
        message: this.toatsMessage,
        position: "middle",
        color: this.toatsColor,
        duration: 2000,
      });
      return toast.present();
    },
  },
};
</script>
<style scoped>
ion-card {
  position: relative;
  left: 0;
  right: 0;
  top: 40%;
  transform: translateY(-50%);
}

ion-content img {
  margin-top: 20px;
  margin-left: 25%;
  width: 50%;
  margin-bottom: 20px;
}
</style>