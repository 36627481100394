<template>
  <ion-page>
    <ion-content>      
      <login-form></login-form>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,  
  IonContent, 
} from "@ionic/vue";
import LoginForm from "../components/auth/LoginForm.vue";
export default {
  name: "login-page",
  components: {
    IonPage,
    IonContent,
    LoginForm
  },
};
</script>
<style scoped>
ion-toolbar {
  --padding-top: 30px;
  --padding-bottom: 20px;
}
</style>
